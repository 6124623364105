<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
    class="white pa-0"
  >
    <div class="hidden-md-and-down">
      <div
        :class="xlDevice ? 'section1-xl' : 'section1-desktop'"
      >
        <v-img
          src="/img/dashboard/Travel-fill.png"
          class="travel-chip chip"
          @click="$router.push('/travel/usa')"
        ></v-img>
        <v-img
          src="/img/dashboard/Auto-fill.png"
          class="auto-chip chip"
          @click="scrollToSection2"
        ></v-img>
        <v-img
          src="/img/dashboard/Life-fill.png"
          class="life-chip chip"
          @click="scrollToSection2"
        ></v-img>
        <v-img
          src="/img/dashboard/Pet-fill.png"
          class="pet-chip chip"
          @click="scrollToSection2"
        ></v-img>
        <!-- <v-chip>auto</v-chip> -->
        <div class="sec1-dsk-title">
          {{ $t('dashboard.section1.h1') }}
        </div>
        <div class="apply-btn">
          <v-btn
            large
            class="mint apply-btn-txt"
            @click="scrollToSection2"
          >
            {{ $t('dashboard.section1.applyNowBtn') }}
          </v-btn>
          <!-- <v-btn
            large
            class="mint apply-btn-txt"
            @click="testaccount"
          >
            test account
          </v-btn>
          <v-btn
            large
            class="mint apply-btn-txt"
            @click="createaccount"
          >
            create account
          </v-btn> -->
        </div>
      </div>
      <div class="section2-desktop hidden-sm-and-down">
        <v-row class="mt-15">
          <v-col class="sec2-dsk-title d-flex justify-center">
            {{ $t('dashboard.section2.h1') }}
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="text-center sec2-dsk-sub">
            {{ $t('dashboard.section2.h2') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            {{ $t('dashboard.section2.copy') }}
          </v-col>
        </v-row>
        <v-row class="mt-15">
          <v-col class="sec2-dsk-title d-flex justify-center pb-0">
            {{ $t('dashboard.section3.h1') }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="10"
            class="d-inline-flex"
          >
            <v-col
              v-for="(product, i) in productCards"
              :key="product.id"
              :index="i"
              class="d-flex flex-column justify-center align-center"
            >
              <v-card
                width="180px"
                height="242"
                class="product-card"
                :class="product.status === 'active' ? 'zoom' : 'inactive'"
                elevation="3"
                @click="goTo(product.route)"
              >
                <v-row>
                  <v-col class="pb-0">
                    <div
                      height="122"
                      width="112"
                    >
                      <v-img
                        :src="product.img"
                        class="mx-auto mt-2 prd-img"
                      ></v-img>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="prd-card-title">
                    {{ product.title }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="product-tag pt-2">
                      {{ product.tag }}
                    </div>
                  </v-col>
                </v-row>
              </v-card>
              <div class="pl-5">
                <v-btn
                  :class="product.txt === 'Learn More' ? 'learn-btn' : 'coming-btn'"
                  @click="goTo(product.learnRoute)"
                >
                  <span :class="product.txt === 'Learn More' ? 'lrn-btn-txt' : 'coming-txt'">{{ product.txt }}</span>
                </v-btn>
              </div>
            </v-col>
          </v-col>
        </v-row>
      </div>
      <div class="sec3-dsk">
        <v-row class="pt-16">
          <v-col class="sec3-dsk-title">
            {{ $t('dashboard.section4.h1') }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-8">
          <v-col
            cols="10"
            class="d-inline-flex justify-center pb-0"
          >
            <v-col class="d-flex justify-center">
              <div
                height="48"
                width="48"
              >
                <v-img
                  src="/img/b-icon.png"
                ></v-img>
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-icon
                class="mint--text dash-icons"
              >
                mdi-account-group
              </v-icon>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-icon
                class="mint--text dash-icons"
              >
                mdi-trophy-award
              </v-icon>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="10"
            class="d-inline-flex"
          >
            <v-col class="d-flex justify-center sec3-sub">
              {{ $t('dashboard.section4.tiles.tile1.h1') }}
            </v-col>
            <v-col class="d-flex justify-center sec3-sub">
              {{ $t('dashboard.section4.tiles.tile2.h1') }}
            </v-col>
            <v-col class="d-flex justify-center sec3-sub">
              {{ $t('dashboard.section4.tiles.tile3.h1') }}
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="10"
            class="d-inline-flex"
          >
            <v-col class="d-flex justify-center sec3-txt">
              {{ $t('dashboard.section4.tiles.tile1.copy') }}
            </v-col>
            <v-col class="d-flex justify-center sec3-txt">
              {{ $t('dashboard.section4.tiles.tile2.copy') }}
            </v-col>
            <v-col class="d-flex justify-center sec3-txt">
              {{ $t('dashboard.section4.tiles.tile3.copy') }}
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-6">
          <v-col
            cols="10"
            class="d-inline-flex justify-center pb-0"
          >
            <v-col class="d-flex justify-center">
              <v-icon
                class="mint--text dash-icons"
              >
                mdi-face-agent
              </v-icon>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-icon
                class="mint--text dash-icons"
              >
                mdi-cash-check
              </v-icon>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-icon
                class="mint--text dash-icons"
              >
                mdi-account-box-multiple
              </v-icon>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="10"
            class="d-inline-flex"
          >
            <v-col class="d-flex justify-center sec3-sub">
              {{ $t('dashboard.section4.tiles.tile4.h1') }}
            </v-col>
            <v-col class="d-flex justify-center sec3-sub">
              {{ $t('dashboard.section4.tiles.tile5.h1') }}
            </v-col>
            <v-col class="d-flex justify-center sec3-sub">
              {{ $t('dashboard.section4.tiles.tile6.h1') }}
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="10"
            class="d-inline-flex pt-0"
          >
            <v-col class="d-flex justify-center sec3-txt">
              {{ $t('dashboard.section4.tiles.tile4.copy') }}
            </v-col>
            <v-col class="d-flex justify-center sec3-txt">
              {{ $t('dashboard.section4.tiles.tile5.copy') }}
            </v-col>
            <v-col class="d-flex justify-center sec3-txt">
              {{ $t('dashboard.section4.tiles.tile6.copy') }}
            </v-col>
          </v-col>
        </v-row>
        <v-row class="mt-6 d-flex justify-center">
          <v-col
            class="text-center ml-6"
          >
            <v-btn
              large
              class="mint apply-btn-txt"
              @click="scrollToSection2"
            >
              {{ $t('dashboard.section4.startPolicyBtn') }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="sec5-dsk">
        <div class="sec5-cnt">
          <v-row>
            <v-col class="sec5-title">
              {{ $t('dashboard.section4.h2') }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="sec5-sub">
              {{ $t('dashboard.section4.copy') }}
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col>
              <v-btn
                large
                class="mint apply-btn-txt"
                @click="scrollToSection2"
              >
                {{ $t('dashboard.section4.startPolicyBtn') }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-img
          src="img/dashboard/mobile-app-mockup.png"
          :class="xlDevice ? 'sec5-xl-phone' : 'sec5-dsk-phone'"
        ></v-img>
      </div>
      <div class="sec4-dsk">
        <v-row class="pt-16">
          <v-col class="sec4-dsk-title">
            {{ $t('dashboard.section5.h1') }}
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="sec4-dsk-sub">
            {{ $t('dashboard.section5.copy') }}
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col class="text-center">
            <v-icon
              v-for="n in 5"
              :key="n"
              large
              class="primary--text"
            >
              mdi-star-outline
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0 d-flex justify-center">
          <v-col
            cols="12"
            class="text-center sec4-dsk-sub pb-0"
          >
            “Amazing doesn’t even begin to cover it. . .
          </v-col>
          <v-col
            cols="6"
            class="sec4-qt pt-0 text-center"
          >
            Life insurance is so important for my family and Blanket Life made it so easy, affordable and comfortable for me to apply. It was truly a quick application process. I was initially concerned about the process but it was easy and I am extremely happy with the results! My family is well covered. Thanks to you Blanket Life.”
          </v-col>
        </v-row>
        <v-row class="mt-n1">
          <v-col class="sec4-qt-name font-weight-bold text-center">
            – Mendy 0.
          </v-col>
        </v-row>
      </div>
      <div class="sec7-dsk">
        <div class="about-frame">
          <v-row>
            <v-col class="sec5-title">
              {{ $t('dashboard.section6.h1') }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="about-txt">
              {{ $t('dashboard.section6.para1') }} <br> <br>
              {{ $t('dashboard.section6.para2') }}
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col>
              <v-btn
                large
                class="mint apply-btn-txt"
                to="/about/team"
              >
                {{ $t('dashboard.section6.learnMoreBtn') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-img
            src="/img/Generali - logo.png"
            class="generali-logo"
          ></v-img>
          <v-img
            src="/img/stillwater - logo.png"
            class="stillwater-logo"
          ></v-img>
          <!-- <v-img
            src="/img/Trupanion - logo.png"
            class="trupanion-logo"
          ></v-img> -->
          <v-img
            src="/img/afficiency - logo.png"
            class="afficiency-logo"
          ></v-img>
        </div>
      </div>
    </div>
    <!--------------------------------TABLET----------------------------->
    <div v-show="mdDevice || smDevice">
      <div
        class="section1-tablet"
      >
        <div class="sec1-tablet-title">
          <v-row>
            <v-col cols="12">
              All your coverage <br> under one blanket
            </v-col>
          </v-row>
        </div>
        <div class="apply-btn">
          <v-btn
            large
            class="mint apply-btn-txt"
            @click="scrollToSection2"
          >
            apply now
          </v-btn>
        </div>
      </div>
      <div class="section2-tablet py-16">
        <v-row>
          <v-col class="sec2-dsk-title d-flex justify-center">
            Coverage means confidence.
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="text-center sec2-dsk-sub">
            And coverage is what we’re all about.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            No matter who you are, what you’re into or what stage of life you’re at… we’ve got <br> you covered. So you can do your thing during the day and sleep worry-free at night. Or the <br> other way around, if you prefer.
          </v-col>
        </v-row>
        <v-row class="mt-15">
          <v-col class="sec2-dsk-title d-flex justify-center pb-0">
            Let's get started.
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center flex-wrap mt-0 px-6">
          <v-col
            v-for="(product, i) in productCards"
            :key="product.id"
            cols="4"
            :index="i"
            class="d-flex flex-column justify-center align-center"
          >
            <v-card
              width="180px"
              height="242"
              class="product-card"
              :class="product.status === 'active' ? 'zoom' : 'inactive'"
              elevation="3"
              @click="goTo(product.route)"
            >
              <v-row>
                <v-col class="pb-0">
                  <div
                    height="122"
                    width="112"
                  >
                    <v-img
                      :src="product.img"
                      class="mx-auto mt-2 prd-img"
                    ></v-img>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="prd-card-title">
                  {{ product.title }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="product-tag pt-2">
                    {{ product.tag }}
                  </div>
                </v-col>
              </v-row>
            </v-card>
            <div class="pl-5">
              <v-btn
                :class="product.txt === 'Learn More' ? 'learn-btn' : 'coming-btn'"
                @click="goTo(product.learnRoute)"
              >
                <span :class="product.txt === 'Learn More' ? 'lrn-btn-txt' : 'coming-txt'">{{ product.txt }}</span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="sec3-tablet py-16">
        <v-row>
          <v-col class="sec3-dsk-title">
            Why Choose Blanket?
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-8">
          <v-col
            cols="12"
            class="d-inline-flex justify-center pb-0"
          >
            <v-col class="d-flex justify-center">
              <div
                height="48"
                width="48"
              >
                <v-img
                  src="/img/b-icon.png"
                ></v-img>
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-icon
                class="mint--text dash-icons"
              >
                mdi-account-group
              </v-icon>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-icon
                class="mint--text dash-icons"
              >
                mdi-trophy-award
              </v-icon>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="12"
            class="d-inline-flex"
          >
            <v-col class="d-flex justify-center sec3-sub">
              All your insurance needs, under one blanket
            </v-col>
            <v-col class="d-flex justify-center sec3-sub">
              Customized coverage for the uniqueness of your life
            </v-col>
            <v-col class="d-flex justify-center sec3-sub">
              A best-in-class, digital insurance experience
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="12"
            class="d-inline-flex"
          >
            <v-col class="d-flex justify-center sec3-txt">
              It’s coverage convenience when you can protect and manage all the things that matter with our comprehensive selection of insurance options—all in one place.
            </v-col>
            <v-col class="d-flex justify-center sec3-txt">
              We do the number-crunching and legwork to give you the insurance coverage designed around you and the things you value.
            </v-col>
            <v-col class="d-flex justify-center sec3-txt">
              With 24/7 policy quotes, purchase, payment and access capabilities, dynamic underwriting, and best-in-class app functionality, we’re streamlining and modernizing how insurance gets done.
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-6">
          <v-col
            cols="12"
            class="d-inline-flex justify-center pb-0"
          >
            <v-col class="d-flex justify-center">
              <v-icon
                class="mint--text dash-icons"
              >
                mdi-face-agent
              </v-icon>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-icon
                class="mint--text dash-icons"
              >
                mdi-cash-check
              </v-icon>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-icon
                class="mint--text dash-icons"
              >
                mdi-account-box-multiple
              </v-icon>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="12"
            class="d-inline-flex"
          >
            <v-col class="d-flex justify-center sec3-sub">
              24/7 customer service for greater peace of mind
            </v-col>
            <v-col class="d-flex justify-center sec3-sub">
              Get the right coverage at a competitive price
            </v-col>
            <v-col class="d-flex justify-center sec3-sub">
              Comprehensive product selection
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="12"
            class="d-inline-flex pt-0"
          >
            <v-col class="d-flex justify-center sec3-txt">
              Unexpected events don’t have off days. If you have questions, we’re one email or phone call away.
            </v-col>
            <v-col class="d-flex justify-center sec3-txt">
              Through our technology and curation of top insurance providers, we ensure that great coverage also comes at a great price, with the reliability and flexibility you should expect from a modern insurance policy.
            </v-col>
            <v-col class="d-flex justify-center sec3-txt">
              We bring all your coverage needs together for your life, business, house, car, pet, travel plans, and more
            </v-col>
          </v-col>
        </v-row>
        <v-row class="mt-6 d-flex justify-center">
          <v-col
            class="text-center ml-6"
          >
            <v-btn
              large
              class="mint apply-btn-txt"
              @click="scrollToSection2"
            >
              get your policy today
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="sec5-tablet py-8">
        <v-row>
          <v-col class="sec5-title text-center">
            We’ve got easy covered.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="sec5-sub text-center">
            Apply, get your quote, select, and pay for your policy in minutes.
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="text-center">
            <v-btn
              large
              class="mint apply-btn-txt mr-0"
              @click="scrollToSection2"
            >
              get your policy today
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="sec4-tablet py-16">
        <v-row>
          <v-col class="sec4-dsk-title">
            Customer Reviews
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="sec4-dsk-sub">
            We’ve got them covered, too.
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col class="text-center">
            <v-icon
              v-for="n in 5"
              :key="n"
              large
              class="primary--text"
            >
              mdi-star-outline
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0 d-flex justify-center">
          <v-col
            cols="12"
            class="text-center sec4-dsk-sub pb-0"
          >
            “Amazing doesn’t even begin to cover it. . .
          </v-col>
          <v-col
            cols="8"
            class="sec4-qt pt-0 text-center"
          >
            Life insurance is so important for my family and Blanket Life made it so easy, affordable and comfortable for me to apply. It was truly a quick application process. I was initially concerned about the process but it was easy and I am extremely happy with the results! My family is well covered. Thanks to you Blanket Life.”
          </v-col>
        </v-row>
        <v-row class="mt-n1">
          <v-col class="sec4-qt-name font-weight-bold text-center">
            – Mendy 0.
          </v-col>
        </v-row>
      </div>
      <div class="sec7-tablet pa-16">
        <v-row>
          <v-col class="sec5-title">
            About Blanket
          </v-col>
        </v-row>
        <v-row>
          <v-col class="about-txt">
            Blanket was founded by experienced insurance professionals who want to modernize the insurance experience by making it faster, easier, and digital. We’ve got YOU covered through a fresh, updated approach to insurance. <br> <br>

            Our platform makes buying insurance frictionless for customers. Our online experience allows consumers to secure insurance policies that meet their individual needs, wherever and whenever they need it - all supported by dedicated customer service agents...
          </v-col>
        </v-row>
        <v-row class="d-flex align-end">
          <v-col class="pb-8">
            <v-img
              src="/img/Generali - logo.png"
              class="generali-logo-tablet"
            ></v-img>
          </v-col><v-col>
            <v-img
              src="/img/stillwater - logo.png"
              class="stillwater-logo-tablet"
            ></v-img>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center">
          <v-col>
            <v-img
              src="/img/Trupanion - logo.png"
              class="trupanion-logo-tablet"
            ></v-img>
          </v-col><v-col>
            <v-img
              src="/img/afficiency - logo.png"
              class="afficiency-logo-tablet"
            ></v-img>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center">
          <v-col>
            <v-img
              src="/img/tugo - logo.png"
              class="tugo-logo-tablet"
            ></v-img>
          </v-col><v-col></v-col>
        </v-row>

        <v-row class="mt-6">
          <v-col>
            <v-btn
              large
              class="mint apply-btn-txt"
              to="/about/team"
            >
              learn more about us
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <!---------------------------------MOBILE----------------------------->
    <div v-show="xsDevice">
      <div
        class="section1-mobile"
      >
      </div>
      <div class="primary pa-6">
        <v-row>
          <v-col
            cols="12"
            class="section1-title-mobile"
          >
            All your coverage under one blanket
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <v-btn
              large
              class="mint apply-btn-txt mr-0"
              @click="scrollToSection2"
            >
              apply now
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="section2-tablet py-16 px-2">
        <v-row>
          <v-col class="sec2-dsk-title d-flex justify-center">
            Coverage means confidence.
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="text-center sec2-dsk-sub">
            And coverage is what we’re all about.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            No matter who you are, what you’re into or what stage of life you’re at… we’ve got you covered. So you can do your thing during the day and sleep worry-free at night. Or the other way around, if you prefer.
          </v-col>
        </v-row>
        <v-row class="mt-15">
          <v-col class="sec2-dsk-title d-flex justify-center pb-0">
            Let's get started.
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center flex-wrap mt-0">
          <v-col
            v-for="(product, i) in productCards"
            :key="product.id"
            cols="6"
            :index="i"
            class="d-flex flex-column justify-center align-center pa-2"
          >
            <v-card
              width="180px"
              height="242"
              class="product-card"
              :class="product.status === 'active' ? 'zoom' : 'inactive'"
              elevation="3"
              @click="goTo(product.route)"
            >
              <v-row>
                <v-col class="pb-0">
                  <div
                    height="122"
                    width="112"
                  >
                    <v-img
                      :src="product.img"
                      class="mx-auto mt-2 prd-img"
                    ></v-img>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="prd-card-title">
                  {{ product.title }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="product-tag pt-2">
                    {{ product.tag }}
                  </div>
                </v-col>
              </v-row>
            </v-card>
            <div class="pl-5">
              <v-btn
                :class="product.txt === 'Learn More' ? 'learn-btn' : 'coming-btn'"
                @click="goTo(product.learnRoute)"
              >
                <span :class="product.txt === 'Learn More' ? 'lrn-btn-txt' : 'coming-txt'">{{ product.txt }}</span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="sec3-mobile py-16 px-3">
        <v-row>
          <v-col class="sec3-dsk-title">
            Why Choose Blanket?
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-8">
          <v-col
            cols="12"
            class="d-inline-flex justify-center"
          >
            <div
              height="48"
              width="48"
            >
              <v-img
                src="/img/b-icon.png"
              ></v-img>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center sec3-sub py-0"
          >
            All your insurance needs, under one blanket
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center sec3-txt py-0"
          >
            It’s coverage convenience when you can protect and manage all the things that matter with our comprehensive selection of insurance options—all in one place.
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center pb-0 pt-10"
          >
            <v-icon
              class="mint--text dash-icons"
            >
              mdi-account-group
            </v-icon>
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center sec3-sub py-0"
          >
            Customized coverage for the uniqueness of your life
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center sec3-txt py-0"
          >
            We do the number-crunching and legwork to give you the insurance coverage designed around you and the things you value.
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center pt-10"
          >
            <v-icon
              class="mint--text dash-icons"
            >
              mdi-trophy-award
            </v-icon>
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center sec3-sub py-0"
          >
            A best-in-class, digital insurance experience
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center sec3-txt py-0"
          >
            With 24/7 policy quotes, purchase, payment and access capabilities, dynamic underwriting, and best-in-class app functionality, we’re streamlining and modernizing how insurance gets done.
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center pt-10"
          >
            <v-icon
              class="mint--text dash-icons"
            >
              mdi-face-agent
            </v-icon>
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center sec3-sub py-0"
          >
            24/7 customer service for greater peace of mind
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center sec3-txt py-0"
          >
            Unexpected events don’t have off days. If you have questions, we’re one email or phone call away.
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            <v-icon
              class="mint--text dash-icons pt-10"
            >
              mdi-cash-check
            </v-icon>
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center sec3-sub py-0"
          >
            Get the right coverage at a competitive price
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center sec3-txt py-0"
          >
            Through our technology and curation of top insurance providers, we ensure that great coverage also comes at a great price, with the reliability and flexibility you should expect from a modern insurance policy.
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            <v-icon
              class="mint--text dash-icons pt-10"
            >
              mdi-account-box-multiple
            </v-icon>
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center sec3-sub py-0"
          >
            Comprehensive product selection
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center sec3-txt py-0"
          >
            We bring all your coverage needs together for your life, business, house, car, pet, travel plans, and more.
          </v-col>
        </v-row>
        <v-row class="mt-6 d-flex justify-center">
          <v-col
            class="text-center pt-10"
          >
            <v-btn
              class="mint text-h5 darkgrey--text font-weight-bold mr-0"
              @click="scrollToSection2"
            >
              get your policy today
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="sec5-mobile py-8 px-2">
        <v-row>
          <v-col class="sec5-title text-center">
            We’ve got easy covered.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="sec5-sub text-center">
            Apply, get your quote, select, and pay for your policy in minutes.
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="text-center">
            <v-btn
              class="mint text-h5 darkgrey--text font-weight-bold mr-0"
              @click="scrollToSection2"
            >
              get your policy today
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="sec4-tablet py-16 px-2">
        <v-row>
          <v-col class="sec4-dsk-title">
            Customer Reviews
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="sec4-dsk-sub">
            We’ve got them covered, too.
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col class="text-center">
            <v-icon
              v-for="n in 5"
              :key="n"
              large
              class="primary--text"
            >
              mdi-star-outline
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0 d-flex justify-center">
          <v-col
            cols="12"
            class="text-center sec4-dsk-sub pb-0"
          >
            “Amazing doesn’t even begin to cover it. . .
          </v-col>
          <v-col
            cols="12"
            class="sec4-qt pt-0 text-center"
          >
            Life insurance is so important for my family and Blanket Life made it so easy, affordable and comfortable for me to apply. It was truly a quick application process. I was initially concerned about the process but it was easy and I am extremely happy with the results! My family is well covered. Thanks to you Blanket Life.”
          </v-col>
        </v-row>
        <v-row class="mt-n1">
          <v-col class="sec4-qt-name font-weight-bold text-center">
            – Mendy 0.
          </v-col>
        </v-row>
      </div>
      <div class="sec7-mobile py-16 px-2">
        <v-row>
          <v-col class="sec5-title">
            About Blanket
          </v-col>
        </v-row>
        <v-row>
          <v-col class="about-txt">
            Blanket was founded by experienced insurance professionals who want to modernize the insurance experience by making it faster, easier, and digital. We’ve got YOU covered through a fresh, updated approach to insurance. <br> <br>

            Our platform makes buying insurance frictionless for customers. Our online experience allows consumers to secure insurance policies that meet their individual needs, wherever and whenever they need it - all supported by dedicated customer service agents...
          </v-col>
        </v-row>
        <v-row class="d-flex align-end">
          <v-col class="pb-8 px-auto">
            <v-img
              src="/img/Generali - logo.png"
              class="generali-logo-tablet mx-auto"
            ></v-img>
          </v-col><v-col>
            <v-img
              src="/img/stillwater - logo.png"
              class="stillwater-logo-tablet mx-auto"
            ></v-img>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center">
          <v-col>
            <v-img
              src="/img/Trupanion - logo.png"
              class="trupanion-logo-tablet mx-auto"
            ></v-img>
          </v-col><v-col>
            <v-img
              src="/img/afficiency - logo.png"
              class="afficiency-logo-tablet mx-auto"
            ></v-img>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center">
          <v-col>
            <v-img
              src="/img/tugo - logo.png"
              class="tugo-logo-tablet mx-auto"
            ></v-img>
          </v-col>
        </v-row>

        <v-row class="mt-6">
          <v-col class="d-flex justify-center">
            <v-btn
              large
              class="mint text-h5 darkgrey--text font-weight-bold mr-0"
              to="/about/team"
            >
              learn more about us
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>
<script>
  export default {
    name: 'Dashboard',
    data () {
      return {
        symetrapdf: '',
        tabs: 0,
        model: null,
        show: false,
        // productCards: [
        //   { title: this.$t('dashboard.section3.tiles.travel.h1'), id: 1, route: '/travel/usa', learnRoute: '/travel/usa', img: '/img/product-cards/travel.png', tag: this.$t('dashboard.section3.tiles.travel.desc'), txt: this.$t('dashboard.section3.learnMoreBtn'), status: 'active' },
        //   { title: this.$t('dashboard.section3.tiles.pet.h1'), id: 6, route: '#', learnRoute: '#', img: '/img/product-cards/pet.png', tag: this.$t('dashboard.section3.tiles.pet.desc'), txt: this.$t('dashboard.section3.comingSoonBtn'), status: 'cs' },
        //   { title: this.$t('dashboard.section3.tiles.home.h1'), id: 2, route: '#', learnRoute: '#', img: '/img/product-cards/home.png', height: 122, width: 112, tag: this.$t('dashboard.section3.tiles.home.desc'), txt: this.$t('dashboard.section3.comingSoonBtn'), status: 'cs' },
        //   { title: this.$t('dashboard.section3.tiles.renters.h1'), id: 3, route: '#', learnRoute: '#', img: '/img/product-cards/renter.png', tag: this.$t('dashboard.section3.tiles.renters.desc'), txt: this.$t('dashboard.section3.comingSoonBtn'), status: 'cs' },
        //   { title: this.$t('dashboard.section3.tiles.life.h1'), id: 4, route: '#', learnRoute: '#', img: '/img/product-cards/life.png', tag: this.$t('dashboard.section3.tiles.life.desc'), txt: this.$t('dashboard.section3.comingSoonBtn'), status: 'cs' },
        //   { title: this.$t('dashboard.section3.tiles.auto.h1'), id: 5, route: '#', learnRoute: '#', img: '/img/product-cards/auto.png', height: 122, width: 112, tag: this.$t('dashboard.section3.tiles.auto.desc'), txt: this.$t('dashboard.section3.comingSoonBtn'), status: 'cs' },
        // ],
        categories: [],
        showncategories: [],
        filteredproducts: [],
        getStarted: false,
        showTitle: true,
      }
    },
    computed: {
      productCards () {
        var json = [
          { title: this.$t('dashboard.section3.tiles.travel.h1'), id: 1, route: '/travel/usa', learnRoute: '/travel/usa', img: '/img/product-cards/travel.png', tag: this.$t('dashboard.section3.tiles.travel.desc'), txt: this.$t('dashboard.section3.learnMoreBtn'), status: 'active' },
          { title: this.$t('dashboard.section3.tiles.pet.h1'), id: 6, route: '#', learnRoute: '#', img: '/img/product-cards/pet.png', tag: this.$t('dashboard.section3.tiles.pet.desc'), txt: this.$t('dashboard.section3.comingSoonBtn'), status: 'cs' },
          { title: this.$t('dashboard.section3.tiles.home.h1'), id: 2, route: '#', learnRoute: '#', img: '/img/product-cards/home.png', height: 122, width: 112, tag: this.$t('dashboard.section3.tiles.home.desc'), txt: this.$t('dashboard.section3.comingSoonBtn'), status: 'cs' },
          { title: this.$t('dashboard.section3.tiles.renters.h1'), id: 3, route: '#', learnRoute: '#', img: '/img/product-cards/renter.png', tag: this.$t('dashboard.section3.tiles.renters.desc'), txt: this.$t('dashboard.section3.comingSoonBtn'), status: 'cs' },
          { title: this.$t('dashboard.section3.tiles.life.h1'), id: 4, route: '#', learnRoute: '#', img: '/img/product-cards/life.png', tag: this.$t('dashboard.section3.tiles.life.desc'), txt: this.$t('dashboard.section3.comingSoonBtn'), status: 'cs' },
          { title: this.$t('dashboard.section3.tiles.auto.h1'), id: 5, route: '#', learnRoute: '#', img: '/img/product-cards/auto.png', height: 122, width: 112, tag: this.$t('dashboard.section3.tiles.auto.desc'), txt: this.$t('dashboard.section3.comingSoonBtn'), status: 'cs' },
        ]
        return json
      },
      getuserkey () {
        let key = ''
        key = this.$store.getters.getcurrentuserkey
        return key
      },
      xlDevice () {
        return this.$vuetify.breakpoint.xlOnly
      },
      lgDevice () {
        return this.$vuetify.breakpoint.lgOnly
      },
      mdDevice () {
        return this.$vuetify.breakpoint.mdOnly
      },
      smDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      xsDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      symetrapdffile () {
        let pdf = ''
        pdf = this.$store.getters.getSymetraPdfs[0]
        return pdf
      },
      productlist () {
        return this.filteredproducts
      }
    },
    created () {
      this.$gtag.pageview('Dashboard')
      this.currentuser = this.$store.getters.getuser
      this.products = this.$store.getters.getProducts
      this.categories = this.$store.getters.getCategories
      this.showncategories = this.categories
      this.filteredproducts = this.products
      // this.$store.dispatch('loadSymetraOccupations')
      // this.$store.dispatch('submitUSTermApplicationAnswers', '')
      // this.$store.dispatch('requestUSTermApplicationQuestions')
      let rec = {
        state: 'AL'
      }
      // this.$store.dispatch('loadSymetraNotices', rec)
      // this.$store.dispatch('symetraWOPRates', { faceamount: '500000', nearestage: '30', termlength: '20' })
      // this.$store.dispatch('symetraADBRates', { faceamount: '250000', nearestage: '30', gender: 'Male' })
      // this.$store.dispatch('symetraICBRates', [{ faceamount: '2000' }, { faceamount: '4000' }, { faceamount: '10000' }])
    },
    methods: {
      createaccount () {
        this.$store.dispatch('createUserAndProfileForUser', { email: 'custsomer2@blanket.com', password: '12223334444' })
      },
      testaccount () {
        // test user id here if it exists
        // is user logged in?
        // test if account exists with an email
        this.$store.dispatch('checkcurrentuserkey')
        this.$store.dispatch('checkaccount', 'nadav@blanket.com')
        this.$store.dispatch('checkaccount', 'mitchell@blanket.com')
      },
      scrollToSection2 () {
        window.scroll({
          top: 700,
          left: 0,
          behavior: 'smooth'
        })
      },
      transition () {
        this.getStarted = !this.getStarted
        setTimeout(() => this.showTitle = !this.showTitle, 400)
      },
      goTo (route) {
        this.$router.push({ path: route })
      },
      filterproducts () {
        console.log('running filter ')
        console.log('products ', this.products)
        console.log('cats ', this.categories)
        this.filteredproducts = []
        this.products.forEach(product => {
          let intersection = product.categories.filter(entry => this.showncategories.includes(entry))
          console.log('intersection for ', product.name, ' is ', intersection)
          if (intersection.length > 0) {
            this.filteredproducts.push(product)
          }
        })
        console.log('filtered products are ', this.filteredproducts)
        // this.categories the selected cats
        // this.products the shown products
      },
      sendevent () {
        this.$gtag.event('blanket', { method: 'Dashboard', campaign: 'none' })
        return 'completed'
      },
    },
  }
</script>
<style scoped>
@media screen and (min-width: 1292px) {
  html {
    transform: none;
    transform-origin: none;
    width: 100%;
    overflow-x: none;
    position: relative;
  }
  .bw-dashboard {
    height: 100vh;
  }
}
.zoom {
    transition: transform .2s;
}
.zoom:hover {
    transform: scale(1.1);
}
.section1-desktop {
  position: relative;
  height: 600px;
  background-image: url('/img/blanketmain-v1.jpg');
  background-position: center;
  background-size: cover;
}
.section1-xl {
  position: relative;
  height: 800px;
  background-image: url('/img/blanketmain-v1.jpg');
  background-position: center;
  background-size: cover;
}
.sec1-dsk-title {
  position: absolute;
left: 9.03%;
right: 42.08%;
top: 61%;
bottom: 20%;
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 52px;
line-height: 110%;
letter-spacing: -0.511589px;
color: #FFFFFF;
text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
}
.apply-btn {
  position: absolute;
bottom: 40px;
left: 9.03%;
}
.apply-btn-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 19px;
line-height: 125%;
letter-spacing: 0.00892857em;
color: #292F36 !important;
}
.section2-desktop {
position: relative;
height: 831.86px;
background: #FFFFFF;
}
.sec2-dsk-title {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
}
.sec2-dsk-sub {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 120%;
/* or 38px */
text-align: center;
letter-spacing: -0.424019px;
/* Color Palette/Dark Grey */
color:
#292F36;
}
.sec2-dsk-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
/* or 24px */
text-align: center;
/* Color Palette/Dark Grey */
color: #292F36;
}
.product-card {
  position:relative;
  display: flex;
flex-direction: column;
align-items: center;
padding: 15px 20px 24px;
gap: 10px;
  background: #FFFFFF;
/* Color Palette/CTA Color */
height: 242px;
border: 1px solid #AAF0C1;
/* Box Shadow Light */
box-shadow: 0px 12px 32px rgba(50, 0, 79, 0.1);
border-radius: 8px;
}
.prd-card-title {
position: absolute;
top: 137px;
left: 0;
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 30px;
line-height: 110%;
/* identical to box height, or 33px */
text-align: center;
letter-spacing: -0.424019px;
/* Color Palette/Pacific Blue */
color: #00A1B7;
}
.product-tag {
position: absolute;
top: 175px;
left: 0;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
/* or 24px */
text-align: center;
/* Color Palette/Dark Grey */
color: #292F36;
}
.lrn-btn-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 16.5px;
line-height: 125%;
/* identical to box height, or 21px */
padding-top: 5px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.00892857em;
text-transform: uppercase;
/* midnight eagle green */
color: #005F6C;
}
.learn-btn {
width: 137px;
height: 33px;
border: 1.37127px solid #005F6C;
border-radius: 5.48507px;
background: #FFFFFF !important;
}
.sec3-dsk {
  height: 1005px;
  background: url('/img/secondary-knit.jpg')
}
.sec3-dsk-title {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
text-align: center;
letter-spacing: -0.424019px;
color: #FFFFFF;
}
.dash-icons {
  font-size: 48px !important;
}
.sec3-sub {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 21px;
line-height: 24px;
/* or 114% */
text-align: center;
/* midnight eagle green */
color: #FFFFFF;
}
.sec3-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
/* or 24px */
text-align: center;
/* gunmetal */
color: #FFFFFF
}
.sec4-dsk {
  height: 635px;
  background: #FFFFFF;
}
.sec4-dsk-title {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
/* or 46px */
text-align: center;
letter-spacing: -0.424019px;
/* Dark Green */
color: #005F6C;
}
.sec4-dsk-sub {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 120%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
}
.sec4-icon {
  font-size: 36px !important;
}
.sec4-qt {
  font-family: 'Poppins';
font-style: italic;
font-weight: 300;
font-size: 21px;
line-height: 140%;
/* or 29px */
text-align: center;
/* Color Palette/Dark Grey */
color: #292F36;
}
.sec4-qt-name {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 21px;
line-height: 140%;
color: #292F36;
}
.sec5-dsk {
  position: relative;
  height: 356px;
  background: url('/img/dash-pattern.jpg');
}
.sec5-dsk-phone {
  position: absolute;
left: 726px;
top: -78px;
}
.sec5-xl-phone {
  position: absolute;
left: 1100px;
top: -78px;
}
.sec5-cnt {
  position: absolute;
width: 516px;
height: 157px;
left: 794px;
top: 75px;
left: 214px;
}
.sec5-title {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
/* or 46px */
letter-spacing: -0.424019px;
/* Color Palette/White (100%) */
color: #FFFFFF;
}
.sec5-sub {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
/* identical to box height, or 24px */
/* Color Palette/White (100%) */
color: #FFFFFF;
}
.sec6-dsk {
  height: 673px;
  background: #FFFFFF;
}
.faq-div {
  margin-top: 40px;
width: 928px;
height: 407px;
}
.faq-titles {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 120%;
letter-spacing: -0.424019px;
color: #292F36;
}
::v-deep .v-expansion-panel {
  border-bottom: 2px solid #AAF0C1 !important;
}
::v-deep .v-expansion-panel-header {
padding-left: 0 !important;
padding-right: 0 !important;
}
::v-deep .v-expansion-panel::before {
  content: none;
}
.sec7-dsk {
position: relative;
height: 570px;
background: url('/img/partner-background.jpg')
}
.about-frame {
position: absolute;
width: 580px;
height: 370px;
left: 130px;
top: 86px;
}
.about-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
color: #FFFFFF;
}
.generali-logo {
  position: absolute;
width: 231.7px;
height: 31.25px;
left: 641px;
top: 77px;
}
.stillwater-logo {
  position: absolute;
width: 252.18px;
height: 128.24px;
left: 927.64px;
top: 0;
}
.trupanion-logo {
  position: absolute;
width: 232.05px;
height: 69.27px;
left: 641px;
top: 170px;
}
.afficiency-logo {
  position:absolute;
  width: 139.36px;
height: 52.76px;
left: 927.64px;
top: 170px;
}
.tugo-logo {
  position: absolute;
width: 154px;
height: 68.96px;
left: 641px;
top: 296px;
}
.auto-chip {
  position: absolute;
  left: 140px;
  top: 8%;
  height: 75px;
  width: 157px;
}
.travel-chip {
  position: absolute;
  right: 10.7%;
  top: 8%;
  height: 75px;
  width: 115px;
}
.life-chip {
  position: absolute;
  left: 41.7%;
  top: 10%;
  height: 75px;
  width: 130px;
}
.pet-chip {
  position: absolute;
  right: 42%;
  top: 23.3%;
  height: 90px;
  width: 75px;
}
.chip:hover {
  cursor: pointer;
}
.coming-btn {
width: 137px;
height: 33px;
border: 1.51871px solid rgba(0, 0, 0, 0.26);
border-radius: 5.48507px;
background: #FFFFFF !important;
}
.coming-btn:hover {
  cursor: default !important;
}
.coming-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 16.5px;
line-height: 125%;
/* identical to box height, or 21px */
padding-top: 5px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.00892857em;
text-transform: uppercase;
color: rgba(0, 0, 0, 0.26);
}
.inactive {
  cursor: default !important;
}

/*----------------------------TABLET---------------------------*/
.section1-tablet {
  position: relative;
  height: 600px;
  background-image: url('/img/blanketmain-v1.jpg');
  background-position: center;
  background-size: cover;
}
.sec1-tablet-title {
  position: absolute;
left: 9.03%;
bottom: 20%;
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 40px;
line-height: 110%;
letter-spacing: -0.511589px;
color: #FFFFFF;
text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
}
.apply-btn {
  position: absolute;
bottom: 40px;
left: 9.03%;
}
.section2-tablet {
position: relative;
background: #FFFFFF;
}
.sec2-dsk-title {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
}
.sec2-dsk-sub {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 120%;
/* or 38px */
text-align: center;
letter-spacing: -0.424019px;
/* Color Palette/Dark Grey */
color:
#292F36;
}
.sec2-dsk-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
/* or 24px */
text-align: center;
/* Color Palette/Dark Grey */
color: #292F36;
}
.product-card {
  position:relative;
  display: flex;
flex-direction: column;
align-items: center;
padding: 15px 20px 24px;
gap: 10px;
  background: #FFFFFF;
/* Color Palette/CTA Color */
height: 242px;
border: 1px solid #AAF0C1;
/* Box Shadow Light */
box-shadow: 0px 12px 32px rgba(50, 0, 79, 0.1);
border-radius: 8px;
}
.prd-card-title {
position: absolute;
top: 137px;
left: 0;
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 30px;
line-height: 110%;
/* identical to box height, or 33px */
text-align: center;
letter-spacing: -0.424019px;
/* Color Palette/Pacific Blue */
color: #00A1B7;
}
.product-tag {
position: absolute;
top: 175px;
left: 0;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
/* or 24px */
text-align: center;
/* Color Palette/Dark Grey */
color: #292F36;
}
.lrn-btn-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 16.5px;
line-height: 125%;
/* identical to box height, or 21px */
padding-top: 5px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.00892857em;
text-transform: uppercase;
/* midnight eagle green */
color: #005F6C;
}
.learn-btn {
width: 137px;
height: 33px;
border: 1.37127px solid #005F6C;
border-radius: 5.48507px;
background: #FFFFFF !important;
}
.sec3-tablet {
  background-image: url('/img/secondary-knit.jpg');
  background-position: center;
  background-size: cover;
}
.sec3-dsk-title {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
text-align: center;
letter-spacing: -0.424019px;
color: #FFFFFF;
}
.dash-icons {
  font-size: 48px !important;
}
.sec3-sub {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 21px;
line-height: 24px;
/* or 114% */
text-align: center;
/* midnight eagle green */
color: #FFFFFF;
}
.sec3-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
/* or 24px */
text-align: center;
/* gunmetal */
color: #FFFFFF
}
.sec4-tablet {
  background: #FFFFFF;
}
.sec4-dsk-title {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
/* or 46px */
text-align: center;
letter-spacing: -0.424019px;
/* Dark Green */
color: #005F6C;
}
.sec4-dsk-sub {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 120%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
}
.sec4-icon {
  font-size: 36px !important;
}
.sec4-qt {
  font-family: 'Poppins';
font-style: italic;
font-weight: 300;
font-size: 21px;
line-height: 140%;
/* or 29px */
text-align: center;
/* Color Palette/Dark Grey */
color: #292F36;
}
.sec4-qt-name {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 21px;
line-height: 140%;
color: #292F36;
}
.sec5-tablet {
  background-image: url('/img/dash-pattern.jpg');
  background-position: center;
  background-size: cover;
}
.sec5-title {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
letter-spacing: -0.424019px;
color: #FFFFFF;
}
.sec5-sub {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
/* identical to box height, or 24px */
/* Color Palette/White (100%) */
color: #FFFFFF;
}
.sec6-dsk {
  height: 673px;
  background: #FFFFFF;
}
.faq-div {
  margin-top: 40px;
width: 928px;
height: 407px;
}
.faq-titles {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 120%;
letter-spacing: -0.424019px;
color: #292F36;
}
::v-deep .v-expansion-panel {
  border-bottom: 2px solid #AAF0C1 !important;
}
::v-deep .v-expansion-panel-header {
padding-left: 0 !important;
padding-right: 0 !important;
}
::v-deep .v-expansion-panel::before {
  content: none;
}
.sec7-tablet {
background-image: url('/img/partner-background.jpg');
background-position: center;
background-size: cover;
}
.about-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
color: #FFFFFF;
}
.generali-logo-tablet {
width: 231.7px;
height: 31.25px;
}
.stillwater-logo-tablet {
width: 252.18px;
height: 128.24px;
}
.trupanion-logo-tablet {
width: 232.05px;
height: 69.27px;
}
.afficiency-logo-tablet {
  width: 139.36px;
height: 52.76px;
}
.tugo-logo-tablet {
width: 154px;
height: 68.96px;
}
.auto-chip {
  height: 75px;
  width: 157px;
}
.travel-chip {
  position: absolute;
  right: 10.7%;
  top: 8%;
  height: 75px;
  width: 115px;
}
.life-chip {
  position: absolute;
  left: 41.7%;
  top: 10%;
  height: 75px;
  width: 130px;
}
.pet-chip {
  position: absolute;
  right: 42%;
  top: 23.3%;
  height: 90px;
  width: 75px;
}
.chip:hover {
  cursor: pointer;
}
.coming-btn {
width: 137px;
height: 33px;
border: 1.51871px solid rgba(0, 0, 0, 0.26);
border-radius: 5.48507px;
background: #FFFFFF !important;
}
.coming-btn:hover {
  cursor: default !important;
}
.coming-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 16.5px;
line-height: 125%;
/* identical to box height, or 21px */
padding-top: 5px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.00892857em;
text-transform: uppercase;
color: rgba(0, 0, 0, 0.26);
}
.inactive {
  cursor: default !important;
}

/*---------------------------MOBILE---------------------------*/
.section1-mobile {
  position: relative;
  height: 375px;
  background-image: url('/img/blanketmain-v1.jpg');
  background-position: center;
  background-size: cover;
}

.section1-title-mobile {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
text-align: center;
letter-spacing: -0.511589px;
color: #FFFFFF;
}

.sec3-mobile {
  background-image: url('/img/secondary-knit-mobile.jpg');
  background-position: center;
  background-size: cover;
}

.sec5-mobile {
  background-image: url('/img/dash-pattern-mobile.jpg');
  background-position: center;
  background-size: cover;
}

.sec7-mobile {
background-image: url('/img/partner-background-mobile.jpg');
background-position: center;
background-size: cover;
}

</style>
